import React from "react"
import { Layout } from "../../components/common/layout"
import { Banner } from "../../components/common/banner"
import { Intent } from "../../components/common/intent"
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ic-controle-de-movimentacao.jpg"
import orangeArrowImage from "../../components/assets/img/ic-orange-arrow.png"

const MovimentacaoPage = () => (
    <Layout infoPage={{ page: "Controle de Movimentação" }}>
        <main className="solucoes-subpage">
            <Banner backgroundImage={backgroundIMG}>
                <h1 className="title bold">
                    CONTROLE
                    <br />
                    <span className="bold" style={{ color: "#FFF" }}>
                        DE MOVIMENTAÇÃO
                    </span>
                </h1>
                <div className="gray-box">
                    <span className="text">
                        Controle de Movimentação de itens e produtos nos
                        processos fabris e logísticos.
                    </span>
                </div>
            </Banner>
            <section className="content">
                <p className="text">
                    Controle de Movimentação de itens e produtos nos processos
                    fabris e logísticos.
                </p>
                <a
                    href="http://sowx.com.br/assets/Sowx-RFID-gestao-de-empilhadeiras.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="flex-box"
                    style={{ marginTop: "1rem" }}
                >
                    <img
                        className="arrow"
                        src={orangeArrowImage}
                        alt="arrow"
                        style={{
                            width: "1.25rem",
                            height: "1.25rem",
                            marginRight: "0.5rem",
                        }}
                    />
                    <span
                        style={{
                            color: "#000",
                            fontStyle: "italic",
                            fontSize: "1.75rem",
                        }}
                    >
                        Clique e baixe o material completo sobre Gestão de
                        Empilhadeiras
                    </span>
                </a>
                <Intent classes="button" to="/#solutions">
                    <span className="medium">DEMAIS SOLUÇÕES</span>
                </Intent>
            </section>
        </main>
    </Layout>
)

export default MovimentacaoPage
