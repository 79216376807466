import React from "react"
import { Intent } from "../intent"
import rfidImage from "../../assets/img/pages/quem-somos/ic-rfid.jpg"

const SaibaMais = () => (
    <section className="saiba-mais">
        <h1 className="title medium">
            CONHEÇA <span className="medium">A TECNOLOGIA RFID</span>
        </h1>
        <article className="banner">
            <img className="rfid" src={rfidImage} alt="logo" />
            <span className="text medium">
                TODOS OS BENEFÍCIOS QUE ESTA{" "}
                <span className="medium">TECNOLOGIA</span> PODE AGREGAR NO SEU
                NEGÓCIO
            </span>
            <Intent classes="button medium" to="/solucoes">
                SAIBA MAIS
            </Intent>
        </article>
    </section>
)

export default SaibaMais
