import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import InputMask from "react-input-mask"
import * as yup from "yup"
import axios from "axios"
import { Modal, Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import phoneImage from "../../../components/assets/img/pages/contact/ic-phone.png"
import mailImage from "../../../components/assets/img/pages/contact/ic-mail.png"
import locationImage from "../../../components/assets/img/ic-localization.png"

const ContactForm = () => {
    const [open, setOpen] = useState(false)

    // toast
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarType, setSnackbarType] = useState("info")

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setSnackbarOpen(false)
    }

    const formSchema = yup.object().shape({
        Name: yup.string().required("Preencha o campo"),
        Email: yup
            .string()
            .email("Digite um endereço de email válido")
            .required("Preencha o campo"),
        Phone: yup.string(),
        Message: yup.string().required("Preencha o campo"),
    })

    const { control, handleSubmit, errors } = useForm({
        resolver: yupResolver(formSchema),
    })

    const handleClose = () => {
        setOpen(false)
    }

    const onSubmit = (values) => {
        const objSubmit = {
            name: values.Name,
            email: values.Email,
            phone: values.Phone,
            message: values.Message,
        }

        axios
            .post(
                "https://adac.dev/partners/sowx/newsletter-api/contact",
                objSubmit
            )
            .then((res) => {
                if (res.data.sended) {
                    setSnackbarType("success")
                    setSnackbarOpen(true)
                }
            })
            .catch(() => {
                setSnackbarType("error")
                setSnackbarOpen(true)
            })
    }

    const SnackBar = () => {
        const Alert = (props) => (
            <MuiAlert elevation={6} variant="filled" {...props} />
        )

        let text
        switch (snackbarType) {
            case "success":
                text =
                    "Sua mensagem foi enviada! Entraremos em contato o mais rápido possível."
                break
            case "error":
                text = "Opa, algo deu errado! :("
                break
            case "info":
                text = "Estamos processando, por favor aguarde."
                break
            default:
        }

        return (
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
                    {text}
                </Alert>
            </Snackbar>
        )
    }

    return (
        <div className="wrapper">
            <div className="infos">
                <div className="desc">
                    <h3 className="title">Entre em contato conosco</h3>
                    <h4 className="description">
                        Dúvidas, sugestões, mais informações?
                        <br />
                        Estamos aguardando seu contato!
                    </h4>
                </div>
                <div className="contact">
                    <a className="item" href="tel:+555130976033">
                        <img src={phoneImage} alt="phone" />
                        <span>(+55 51) 3097.6033</span>
                    </a>
                    <a className="item" href="mailto:contato@sowx.com.br">
                        <img src={mailImage} alt="phone" />
                        <span>contato@sowx.com.br</span>
                    </a>
                </div>
            </div>
            <div className="form__out">
                <form
                    className="wrapper-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="box__input">
                        <Controller
                            as={<input aria-label="input" />}
                            placeholder="Nome"
                            name="Name"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Name && <span>{errors.Name.message}</span>}
                    </div>
                    <div className="box__input">
                        <Controller
                            as={<input aria-label="input" />}
                            className="form-email"
                            placeholder="Email"
                            name="Email"
                            control={control}
                            defaultValues=""
                        />
                        {errors.Email && <span>{errors.Email.message}</span>}
                    </div>
                    <div className="box__input">
                        <Controller
                            as={
                                <InputMask
                                    mask="(99) 99999-9999"
                                    maskChar=" "
                                />
                            }
                            className="form-phone"
                            placeholder="Telefone"
                            name="Phone"
                            control={control}
                            defaultValues=""
                        />
                        {errors.Phone && <span>{errors.Phone.message}</span>}
                    </div>
                    <div className="box__input">
                        <Controller
                            as={<textarea aria-label="input" />}
                            className="form-message"
                            placeholder="Mensagem"
                            name="Message"
                            control={control}
                        />
                        {errors.Message && (
                            <span>{errors.Message.message}</span>
                        )}
                    </div>
                    <div>
                        <button type="submit" className="button">
                            ENVIAR
                        </button>
                    </div>
                </form>
                <section>
                    <div className="item__find">
                        <img className="logo" src={locationImage} alt="logo" />
                        <span className="text medium">
                            Sowx Trace & Process
                            <br />
                            Rua Engenheiro Jorge Schury, 522
                            <br />
                            Novo Hamburgo - RS - Brasil
                            <br />
                            CEP: 93534-245
                        </span>
                    </div>
                    <div className="item__find">
                        <img className="logo" src={phoneImage} alt="logo" />
                        <span className="text medium">
                            <a href="tel:+555130976033">(+55 51) 3097.6033</a>
                            <br />
                            <a href="mailto:contato@sowx.com.br">
                                CONTATO@SOWX.COM.BR
                            </a>
                        </span>
                    </div>
                </section>
                {/* <iframe
                    title="localização"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3466.7438345334162!2d-51.10830868489372!3d-29.669206982020405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951943dccaf55763%3A0x6f2f9baea6518823!2sR.%20Eng.%20Jorge%20Schury%2C%20522%20-%20S%C3%A3o%20Jos%C3%A9%2C%20Novo%20Hamburgo%20-%20RS%2C%2093524-040!5e0!3m2!1spt-BR!2sbr!4v1601128725122!5m2!1spt-BR!2sbr"
                    frameBorder="0"
                    allowFullScreen=""
                    aria-hidden="false"
                /> */}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div id="contato-modal">
                    <h2>Sua mensagem foi enviada!</h2>
                    <p>Logo nossa equipe entrará em contato com você.</p>
                </div>
            </Modal>
            <SnackBar />
        </div>
    )
}

export default ContactForm
