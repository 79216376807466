import React from "react"
import { Layout } from "../components/common/layout"
import HomeSlider from "./homepage/slider/HomeSlider"
import Segments from "./homepage/segments/Segments"
import Solutions from "./homepage/solutions/Solutions"
import { SaibaMais } from "../components/common/saiba-mais"
// import { NewsLetter } from "../components/common/news-letter"

const IndexPage = () => (
    <Layout infoPage={{ page: "home" }}>
        <main className="home">
            <HomeSlider />
            <Segments />
            <Solutions />
            <SaibaMais />
            {/* <NewsLetter /> */}
        </main>
    </Layout>
)

export default IndexPage
