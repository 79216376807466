import React from "react"
import { Slider } from "../../../components/common/slider"

const HomeSlider = () => (
    <section className="slider">
        <Slider>
            <article id="slide" className="slide first">
                <div className="content">
                    <h1 className="title bold">MAXIMIZE seus resultados</h1>
                    <p className="paragraph">
                        {/* com Gestão de DADOS baseada em FATOS */}
                        {/* com RFID e IOT na Gestão da sua EMPRESA */}
                        use RFID e IoT para a Gestão de DADOS da sua EMPRESA
                    </p>
                </div>
            </article>
            <article id="slide" className="slide second">
                <div className="content">
                    <h1 className="title bold">
                        RFID{" "}
                        <span className="smaller">
                            Identificação por radiofrequência
                        </span>
                    </h1>
                    <p className="paragraph">
                        MAIOR CONFIABILIDADE SEM INTERVENÇÃO HUMANA
                    </p>
                </div>
            </article>
        </Slider>
    </section>
)

export default HomeSlider
