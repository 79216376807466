import React from "react";
import { Layout } from "../../components/common/layout";
import { Banner } from "../../components/common/banner";
import { Intent } from "../../components/common/intent";
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ic-garantia-de-autenticidade.jpg";

const AutenticidadePage = () => (
        <Layout infoPage={{page: "Garantia de Autenticidade"}}>
            <main className="solucoes-subpage">
                <Banner backgroundImage={backgroundIMG}>
                    <h1 className="title bold">GARANTIA<br/><span className="bold" style={{ color: "#FFF" }}>DE AUTENTICIDADE</span></h1>                    
                </Banner>
                <section className="content">
                    <p className="text">Saiba se seu produto é legítimo</p>
                    <p className="text">Utilize RFID e não deixe seu lucro nas mãos da pirataria!</p>
                    <Intent classes="button" to="/#solutions">
                        <span className="medium">DEMAIS SOLUÇÕES</span>
                    </Intent>
                </section>
            </main>
        </Layout>
    )

export default AutenticidadePage;