import React from "react";

const QuestionBanner = (props) => {
    const { children } = props;

    return (
        <section className="banner question">
            {children}
        </section>
    )
}

export default QuestionBanner;