import React from "react";
import Slider from "react-slick";

const MySlider = (props) => {
    const { children } = props;

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: "slider-dots",
        autoplay: true,
        autoplaySpeed: 8000,
    };  

    return (
        <Slider {...settings}>
            {children}
        </Slider>
    )
}

export default MySlider;