import React from "react";
import { Layout } from "../../components/common/layout";
import { Banner } from "../../components/common/banner";
import { Intent } from "../../components/common/intent";
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ic-controle-de-rti.jpg";

const ItensRetornaveisPage = () => (
        <Layout infoPage={{page: "Controle de Itens Retornáveis"}}>
            <main className="solucoes-subpage">
                <Banner backgroundImage={backgroundIMG}>
                    <h1 className="title bold">CONTROLE<br/><span className="bold" style={{ color: "#FFF" }}>DE ITENS RETORNÁVEIS</span></h1>
                    <div className="gray-box">
                        <span className="text">Returnable Transport Items</span>
                    </div>
                </Banner>
                <section className="content">
                    <p className="text">Controle de Pallets, Barris, Cilindros, Gaiolas Metálicas, Gaiolas Industriais, Caixas Plásticas, e muitos outros itens retornáveis.</p>
                    <p className="text">Com RFID você saberá onde ele se encontra.</p>
                    <Intent classes="button" to="/#solutions">
                        <span className="medium">DEMAIS SOLUÇÕES</span>
                    </Intent>
                </section>
            </main>
        </Layout>
    )

export default ItensRetornaveisPage;