import React from "react"
import { Layout } from "../components/common/layout"
// import { Banner } from "../components/common/banner"
import ContactForm from "./contato/contact-form/ContactForm"

const ContactPage = () => (
    <Layout infoPage={{ page: "Contato" }}>
        <main className="contato">
            {/* <Banner>
                <div className="content">
                    <h1 className="title bold">
                        <span className="bold">
                            ESTAMOS AGUARDANDO SEU CONTATO!
                        </span>
                    </h1>
                </div>
            </Banner> */}
            <ContactForm />
        </main>
    </Layout>
)

export default ContactPage
