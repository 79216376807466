import React from "react"

const ContactButton = ({ fixed = true }) => {
    const handleButtonClick = () => {
        // window.scrollTo({ top: 0, behavior: "smooth" })
        window.open(
            "https://api.whatsapp.com/send/?phone=555198800846&text=Olá!&app_absent=0",
            "_blank"
        )
    }

    return (
        <button
            type="button"
            className={`contact-button ${fixed && "fixed"}`}
            onClick={handleButtonClick}
            onKeyDown={handleButtonClick}
        >
            <span>Fale com um especialista</span>
        </button>
    )
}

export default ContactButton
