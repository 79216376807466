import React from "react"
import { Intent } from "../intent"
import logoImage from "../../assets/img/footer/ic-logo.png"
import { ContactButton } from "../contact-button"

const Footer = () => (
    <section className="upper">
        <div className="content">
            <div className="first-item">
                <Intent to="/">
                    <img className="logo" src={logoImage} alt="logo" />
                </Intent>
                <span style={{ alignSelf: "flex-start" }}>
                    <ContactButton fixed={false} />
                </span>
                {/* <div className="item__find">
                    <img className="logo" src={localizationImage} alt="logo" />
                    <span className="text medium">
                        Sowx Trace & Process
                        <br />
                        Rua Engenheiro Jorge Schury, 522
                        <br />
                        Novo Hamburgo - RS - Brasil
                        <br />
                        CEP: 93534-245
                    </span>
                </div>
                <div className="item__find">
                    <img className="logo" src={phoneImage} alt="logo" />
                    <span className="text medium">
                        <a href="tel:+555130976033">(+55 51) 3097.6033</a>
                        <br />
                        <a href="mailto:contato@sowx.com.br">
                            CONTATO@SOWX.COM.BR
                        </a>
                    </span>
                </div> */}
            </div>
            <div className="second-item">
                <Intent to="/solucoes">SOLUÇÕES</Intent>
                <div className="wrapper">
                    <div className="list">
                        <Intent to="/solucoes/controle-de-acesso">
                            CONTROLE DE ACESSO
                        </Intent>
                        <Intent to="/solucoes/garantia-de-autenticidade">
                            GARANTIA DE AUTENTICIDADE
                        </Intent>
                        <Intent to="/solucoes/controle-de-expedicao">
                            CONTROLE DE EXPEDIÇÃO
                        </Intent>
                        <Intent to="/solucoes/inventario-em-tempo-real">
                            INVENTÁRIO EM TEMPO REAL
                        </Intent>
                        <Intent to="/solucoes/controle-de-itens-retornaveis">
                            CONTROLE DE ITENS RETORNÁVEIS
                        </Intent>
                        <Intent to="/solucoes/lavanderia-industrial">
                            LAVANDERIA INDUSTRIAL
                        </Intent>
                    </div>
                    <div className="list">
                        <Intent to="/solucoes/controle-de-movimentacao">
                            CONTROLE DE MOVIMENTAÇÃO
                        </Intent>
                        <Intent to="/solucoes/logistica-gestao-de-frotas">
                            LOGÍSTICA GESTÃO DE FROTAS
                        </Intent>
                        <Intent to="/solucoes/gestao-total-de-pneus">
                            GESTÃO TOTAL DE PNEUS
                        </Intent>
                        <Intent to="/solucoes/rastreabilidade">
                            RASTREABILIDADE
                        </Intent>
                        <Intent to="/solucoes/ferrovia-e-mineracao">
                            FERROVIA E MINERAÇÃO
                        </Intent>
                        <Intent to="/solucoes/smart-sensor">
                            SMART SENSOR
                        </Intent>
                    </div>
                </div>
            </div>
            {/* <div className="third-item">
                <a href="http://www.sowx.com.br/blog/">BLOG</a>
            </div> */}
            <div className="fourth-item">
                <Intent to="/contato">CONTATO</Intent>
            </div>
        </div>
    </section>
)

export default Footer
