import React from "react"
import image1 from "../../../components/assets/img/pages/home/ic-1.png"
import image2 from "../../../components/assets/img/pages/home/ic-2.png"
import image3 from "../../../components/assets/img/pages/home/ic-3.png"
import image4 from "../../../components/assets/img/pages/home/ic-4.png"
import image5 from "../../../components/assets/img/pages/home/ic-5.png"
import image6 from "../../../components/assets/img/pages/home/ic-6.png"
import image8 from "../../../components/assets/img/pages/home/ic-8.svg"
import image9 from "../../../components/assets/img/pages/home/ic-9.svg"
import image10 from "../../../components/assets/img/pages/home/ic-10.svg"
import image11 from "../../../components/assets/img/pages/home/ic-11.svg"

const Segments = () => (
    <section className="segments">
        <h1 className="title medium">
            SEGMENTOS <span className="medium">QUE ATUAMOS</span>
        </h1>
        <article className="wrapper">
            <figure className="item">
                <img className="image" src={image1} alt="segment" />
                <span className="text medium">DISTRIBUIÇÃO E LOGÍSTICA</span>
            </figure>
            <figure className="item">
                <img className="image" src={image2} alt="segment" />
                <span className="text medium">MANUFATURA E PRODUÇÃO</span>
            </figure>
            <figure className="item">
                <img className="image" src={image3} alt="segment" />
                <span className="text medium">SAÚDE</span>
            </figure>
            <figure className="item">
                <img className="image" src={image4} alt="segment" />
                <span className="text medium">VAREJO</span>
            </figure>
            <figure className="item">
                <img className="image" src={image5} alt="segment" />
                <span className="text medium">HOTÉIS</span>
            </figure>
            <figure className="item">
                <img className="image" src={image6} alt="segment" />
                <span className="text medium">LAVANDERIA INDUSTRIAL</span>
            </figure>
            {/* <figure className="item">
                    <img className="image" src={require("../../../components/assets/img/pages/home/ic-7.png")} alt="segment"/>
                    <span className="text medium">MOVELEIRO</span>
                </figure> */}
            <figure className="item">
                <img className="image" src={image8} alt="segment" />
                <span className="text medium">FERROVIA E MINERAÇÃO</span>
            </figure>
            <figure className="item">
                <img className="image" src={image9} alt="segment" />
                <span className="text medium">ÓLEO E GÁS</span>
            </figure>
            <figure className="item">
                <img className="image" src={image10} alt="segment" />
                <span className="text medium">AGRICULTURA</span>
            </figure>
            <figure className="item">
                <img className="image" src={image11} alt="segment" />
                <span className="text medium">EDUCAÇÃO</span>
            </figure>
        </article>
    </section>
)

export default Segments
