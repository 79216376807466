import React from "react"
import { Layout } from "../../components/common/layout"
import { Banner } from "../../components/common/banner"
import { Intent } from "../../components/common/intent"
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ic-gestao-total-de-pneus.jpg"

const GestaoTotalDePneusPage = () => (
    <Layout infoPage={{ page: "Gestão Total de Peneus" }}>
        <main className="solucoes-subpage">
            <Banner backgroundImage={backgroundIMG}>
                <h1 className="title bold">
                    GESTÃO TOTAL
                    <br />
                    <span className="bold" style={{ color: "#FFF" }}>
                        DE PNEUS
                    </span>
                </h1>
                <div className="gray-box">
                    <span className="text">
                        Utilize nossa solução RFID e aumente a vida útil dos
                        pneus de sua frota em até 25% e tenha até 3% de economia
                        de combustível.
                    </span>
                </div>
            </Banner>
            <section className="content">
                <p className="text">
                    <span className="bold orange">Gestão total</span> de Pneus
                </p>
                <p className="text">
                    Utilize nossa solução RFID e aumente a vida útil dos pneus
                    de sua frota em até 25% e tenha até 3% de economia de
                    combustível.
                </p>
                <p className="text">
                    Com a solução SOWX você terá uma gestão com base em fatos e
                    com o mínimo de intervenção humana, alcançando economia
                    significativa em ativos pneus e consumo de combustível.
                </p>
                <p className="text">
                    Com a utilização de Tecnologia RFID é possível obter de
                    maneira automática e online os seguintes benefícios:
                </p>
                <ul className="list">
                    <li className="text">
                        Cálculo do CPK (Custo por KM Rodado) automático.
                    </li>
                    <li className="text">
                        Inventário de pneus e frota online.
                    </li>
                    <li className="text">
                        Atingir número máximo de recapagem que um pneu possa
                        ofrecer.
                    </li>
                    <li className="text">
                        Quais pneus tem que realizar a calibragem, alinhamento e
                        balanceamento.
                    </li>
                    <li className="text">
                        Saiba quais pneus foram trocados indevidamente.
                    </li>
                    <li className="text">Inibição de roubo de pneus.</li>
                    <li className="text">
                        Indicadores de Pneus, Frotas e Motorista.
                    </li>
                    <li className="text">
                        Métricas para obtenção do melhor custo benefício de
                        Pneus e Recapagens.
                    </li>
                    <li className="text">
                        Quilometragem percorrida por ciclo de vida do pneu.
                    </li>
                    <li className="text">
                        Planejamento de Compra e Recapagem de Pneus.
                    </li>
                </ul>
                {/* <a href="http://sowx.com.br/assets/Sowx-RFID-gestao-de-pneus.pdf" target="_blank" rel="noreferrer" className="flex-box" style={{marginTop: '1rem'}}>
                        <img className="arrow" src={require("../../components/assets/img/ic-orange-arrow.png")} alt="arrow" style={{width: '1.25rem', height: '1.25rem', marginRight: '0.5rem'}} />
                        <span style={{color: "#000", fontStyle: "italic", fontSize: '1.75rem'}}>Clique e baixe o material completo sobre Gestão de Pneus</span>
                    </a> */}
                <Intent classes="button" to="/#solutions">
                    <span className="medium">DEMAIS SOLUÇÕES</span>
                </Intent>
            </section>
        </main>
    </Layout>
)

export default GestaoTotalDePneusPage
