import React from "react"
import { Layout } from "../components/common/layout"
import { Banner } from "../components/common/banner"
import { SaibaMais } from "../components/common/saiba-mais"
// import { NewsLetter } from "../components/common/news-letter";
import Partners from "./parceiros/partners/Partners"

const PartnersPage = () => (
    <Layout infoPage={{ page: "Parceiros" }}>
        <main className="parceiros">
            <Banner>
                <h1 className="title bold">
                    NOSSOS
                    <br />
                    <span className="bold">PARCEIROS</span>
                </h1>
            </Banner>
            <Partners />
            <SaibaMais />
            {/* <NewsLetter /> */}
        </main>
    </Layout>
)

export default PartnersPage
