const data = {
    questions: [
        {
            id: "name",
            question: "Digite o nome da empresa",
            type: "string",
        },
        {
            id: "email",
            question: "Digite seu e-mail",
            type: "string",
            validation_type: "email",
            validation_text: "Deve ser um endereço de e-mail válido"
        },
        {
            id: "question_1",
            question: "Quantos Veículos estão em operação?",
            type: "number",
        },
        {
            id: "question_2",
            question: "Quantos Semirreboques estão em operação?",
            type: "number",
        },
        {
            id: "question_3",
            question: "Quantos pneus estão em operação?",
            type: "number",
        },
        {
            id: "question_4",
            question: "Quantos pneus novos são comprados por mês?",
            type: "number",
        },
        {
            id: "question_5",
            question: "Quantos pneus são recapados por mês?",
            type: "number",
        },
        {
            id: "question_6",
            question: "Quantos pneus Novos estão em estoque?",
            type: "number",
        },
        {
            id: "question_7",
            question: "Quantos pneus Recapados estão em estoque?",
            type: "number",
        },
        {
            id: "question_8",
            question: "Qual o valor médio do pneu novo?",
            type: "number",
        },
        {
            id: "question_9",
            question: "Qual o valor médio do pneu recapado?",
            type: "number",
        },
        {
            id: "question_10",
            question: "Quantos pneus são sucateados/Descartados/estourados por mês?",
            type: "number",
        },
        {
            id: "question_11",
            question: "Quantos pneus bons para último uso são vendidos por mês?",
            type: "number",
        },
        {
            id: "question_12",
            question: "Qual valor médio de venda dos pneus?",
            type: "number",
        },
        {
            id: "question_13",
            question: "Qual o consumo médio de combustível por veículo? (km/l)",
            type: "number",
        },
        {
            id: "question_14",
            question: "Qual a KM média mensal por veículo?",
            type: "number",
        },
        {
            id: "question_15",
            question: "Qual a KM média mensal por semirreboque?",
            type: "number",
        },
        {
            id: "question_16",
            question: "Veículo em viagem, Qual tonelada média transportada?",
            type: "number",
        },
        {
            id: "question_17",
            question: "Qual o valor médio do diesel?",
            type: "number",
        },
        {
            id: "question_18",
            question: "Qual o gasto médio por veículo em manutenção mecânica por ano?",
            type: "number",
        },
        {
            id: "question_19",
            question: "Qual o gasto médio por semirreboque em manutenção mecânica por ano?",
            type: "number",
        },
        {
            id: "question_20",
            question: "Quantas multas de trânsito por excesso de velocidade são emitidas por ano? (considerar frota inteira)",
            type: "number",
        },
        {
            id: "question_21",
            question: "Valor médio das multas de trânsito por excesso de velocidade?",
            type: "number",
        },
        {
            id: "question_22",
            question: "Quantos acidentes de trânsito ocorrem por ano?",
            type: "number",
        },
        {
            id: "question_23",
            question: "Qual o gasto médio por acidente de trânsito?",
            type: "number",
        },
        {
            id: "question_24",
            question: "Qual o gasto médio em indenizações/honorários advocatícios ref. Processos trabalhistas por ano?",
            type: "number",
        },
        {
            id: "question_25",
            question: "Qual valor gasto por mês em horas extras",
            type: "number",
        },
    ]
};

export default data;