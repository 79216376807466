import React from "react"
import { Layout } from "../../components/common/layout"
import { Banner } from "../../components/common/banner"
import { Intent } from "../../components/common/intent"
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/smart-sensor.jpg"
import orangeArrowImage from "../../components/assets/img/ic-orange-arrow.png"

const SmartSensorPage = () => (
    <Layout infoPage={{ page: "Smart Sensor" }}>
        <main className="solucoes-subpage">
            <Banner backgroundImage={backgroundIMG}>
                <h1 className="title bold">SMART SENSOR</h1>
            </Banner>
            <section className="content">
                <p className="text bold orange">Smart Sensor</p>
                <ul className="list">
                    <li className="text">
                        Sensor de monitoramento de vibração e operação de
                        máquinas
                    </li>
                    <li className="text">
                        Sensor de vibração e temperatura de rolamentos em
                        correias transportadoras para prevenção de acidentes
                    </li>
                    <li className="text">
                        Redução de custos com manutenção corretiva
                    </li>
                    <li className="text">
                        Redução do tempo de máquinas desligadas em processo de
                        manutenção
                    </li>
                    <li className="text">
                        Coleta de informações de operação das máquinas de forma
                        on-line
                    </li>
                </ul>
                <a
                    href="http://sowx.com.br/assets/sowx-RFID-e-smart-sensor.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="flex-box"
                    style={{ marginTop: "1rem" }}
                >
                    <img
                        className="arrow"
                        src={orangeArrowImage}
                        alt="arrow"
                        style={{
                            width: "1.25rem",
                            height: "1.25rem",
                            marginRight: "0.5rem",
                        }}
                    />
                    <span
                        style={{
                            color: "#000",
                            fontStyle: "italic",
                            fontSize: "1.75rem",
                        }}
                    >
                        Clique e baixe o material completo sobre Smart Sensor
                    </span>
                </a>
                <Intent classes="button" to="/#solutions">
                    <span className="medium">DEMAIS SOLUÇÕES</span>
                </Intent>
            </section>
        </main>
    </Layout>
)

export default SmartSensorPage
