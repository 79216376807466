import React from "react"
import { Layout } from "../components/common/layout"
import { Banner } from "../components/common/banner"
import { QuestionBanner } from "../components/common/question-banner"
import SolutionList from "./solucoes/solution-list/SolutionList"
import circuitImage from "../components/assets/img/ic-circuit.png"

const SolutionsPage = () => (
    <Layout infoPage={{ page: "Soluções" }}>
        <main className="solucoes">
            <Banner>
                <div className="content">
                    <div className="image-box">
                        <img
                            className="image"
                            src={circuitImage}
                            alt="circuit"
                        />
                        <h1 className="title bold">RFID</h1>
                    </div>
                    {/* <h1 className="title bold">PARA A SOWX</h1> */}
                    <span
                        className="subtitle medium"
                        style={{ fontSize: "36px" }}
                    >
                        Gestão de Dados, baseada em Fatos
                        <br />
                        ESSE É O NOSSO KNOW-HOW
                    </span>
                    {/* <span className="subtitle medium background">TRACE & PROCESS</span> */}
                </div>
            </Banner>
            <QuestionBanner>
                <h1 className="title white bold">
                    <a
                        href="https://docs.google.com/forms/d/1KAsIUpoFVe-mKoVHZwuUUhAwsCypUM-2YnZuBdlPt6o/edit"
                        target="_blank"
                        rel="noreferrer"
                        className="black bold"
                    >
                        Clique aqui
                    </a>{" "}
                    e responda ao Questionário de Qualificação de Projeto RFID
                </h1>
            </QuestionBanner>
            <SolutionList />
        </main>
    </Layout>
)

export default SolutionsPage
