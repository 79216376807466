const data = [
    {
        Name: "home",
        URL: "/",
    },
    {
        Name: "quem somos",
        URL: "/quem-somos",
    },
    {
        Name: "soluções",
        URL: "/solucoes",
    },
    {
        Name: "diagnóstico",
        URL: "/diagnostico",
    },
    // {
    // 	Name: "parceiros",
    // 	URL: "/parceiros"
    // },
    // {
    //     Name: "blog",
    //     URL: "http://www.sowx.com.br/blog/",
    // },
    {
        Name: "contato",
        URL: "/contato",
    },
]

export default data
